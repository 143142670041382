import React from 'react'
import Layout from '../layout/Layout'
import { Container, Row } from 'reactstrap'

const NotFoundPage = () => (
  <Layout>
    <Container className="mb-5 mt-5">
      <Row className="text-center">
        <div>
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
